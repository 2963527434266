import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {type: 'home'}
  },
  {
    path: '/produkte',
    name: 'Produkt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home,
    meta: {type: 'produkte'}
  },
  {
    path: '/produkte/:product',
    name: 'Produkte',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home,
    meta: {type: 'produkte'}
  },

  {
    path: '/:slug',
    name: 'Page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home,
    meta: {type: 'page'}
  },
  {
    path: '/:slug/:subslug',
    name: 'Subpage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home,
    meta: {type: 'page', 'subpage': true}
  },

]

const router = new VueRouter({
  mode: 'history',

  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

export default router
