<template>
  <div class="products subpage" v-if="products && product" :style="{backgroundImage:`url(${getBackground()})`}"
       :class="product?product.uppercat[0]:''" v-touch:swipe.right="goLeft" v-touch:swipe.left="goRight">
    <div class="arrow left">

      <router-link v-if="this.position>0" :to="'/produkte/'+prevLink()">
        <img class="chevron left" src="@/assets/img/chevron-left.svg" alt="">
      </router-link>
    </div>
    <div class="arrow right">

      <router-link v-if="products[this.position+1]" :to="'/produkte/'+nextLink()"><img class="chevron right"
                                                                                       src="@/assets/img/chevron-left.svg"
                                                                                       alt=""></router-link>
    </div>
    <div class="bg" :style="{backgroundImage:`url(${getBackground()})`}">

    </div>
    <div class="content">
      <div class="infoheader">
        <!--        <div>{{product}}</div>-->
        <div >
          <h3 v-html="product.acf.uberschrift.oberzeile"></h3>
          <h2 v-html="product.acf.uberschrift.unterzeile"></h2>
        </div>
      </div>
      <div class="textfeld" v-html="product.acf.text"></div>
      <div class="nahrwerte">
        <div class="twocols">
          <table>
            <tr v-for="n in product.acf.nahrwerte" :key="n.beschriftung">
              <td class="desc" v-html="n.beschriftung"></td>
              <td v-html="n.wert"></td>
            </tr>
            <tr v-if="product.acf.zutatenliste && !zutaten">
              <td class="desc">
                <a @click="openZutaten">
                Zutatenliste öffnen
                </a>
              </td>
              <td>
              </td>
            </tr>
          </table>
          <button v-if="product.acf.shoplink">

            <a :href="product.acf.shoplink" target="_blank">Zum Shop</a>
          </button>
        </div>

        <div class="zutaten textfeld" v-if="zutaten" style="position: relative">
          <div style="position: absolute;top:-200px" ref="zutaten"></div>
          <div v-html="product.acf.zutatenliste"></div>
        </div>

      </div>
      <!--    {{product.title.rendered}}-->
      <!--    {{product.acf.uberschrift}}-->
      <!--{{product.acf.text}}-->
      <!--{{product.acf.bild.sizes.large}}-->
      <!--{{product.acf.shoplink}}-->
      <!--      Cats: {{ cats }}-->
      <!--      Subcats: {{ subcats }}-->
    </div>
    <div class="praline">

      <img :src="product.acf.bild.sizes.wide" alt="" style="max-width: 100%">
    </div>
  </div>

</template>

<script>
import store from "@/store";

export default {
  name: "Products.vue",
  data() {
    return {
      zutaten: false
    }
  },
  computed: {

    cats() {
      return store.state.cats
    },
    subcats() {
      return store.state.subcats
    },
    position() {
      let arr = this.products.map(function (s) {
        return s.slug
      })
      return this.product?arr.indexOf(this.product.slug):0
    }
  },
  watch: {
    zutaten() {
      if (this.zutaten)
        setTimeout(function () {
          this.$refs.zutaten.scrollIntoView()
        }.bind(this), 200);
    },
    $route() {
      this.zutaten = false
    }
  },
  methods: {
    // getSubWidth() {
    //   return 100 / this.subpages.length + '%'
    // },
    goLeft() {
      if (this.position > 0) this.$router.push('/produkte/' + this.prevLink())
    },
    goRight() {
      if (this.products[this.position + 1]) this.$router.push('/produkte/' + this.nextLink())
    },
    nextLink() {
      return this.products[this.position + 1].slug
    },
    prevLink() {
      return this.products[this.position - 1].slug
    },
    getBackground() {
      return this.product?this.product.acf.bild.sizes.wide:''
    },
    openZutaten() {
      this.zutaten = !this.zutaten;
    }
  },
  props: ['products', 'product'],

}
</script>

<style scoped lang="scss">
.bg {
  width: 50%;
  background-size: calc(100vh - 100px);
  background-position: center 10px;
  background-repeat: no-repeat;

  @include mobile {
    background: none !important;
    width: 45%;

  }
}

.praline {
  display: none;
}

.schokoladiges-fruchtiges {
  .bg {
    background-position: center 100px;
  }

  .twocols {
    @include mobile {
      display: flex;
      align-items: flex-start;
      table {
        width: 60%;
      }
      button {
        width: 40%;
      }
    }
  }

  .praline {
    @include mobile {
      display: block;
    }
  }
}

@include mobile {

  .schokoladiges-fruchtiges {
    padding-bottom: 40px;
    background-position: center bottom !important;
    background-size: 130% !important;
    background: none !important;

    .bg {
      width: 10%;

    }

    .content {
      width: 80%;
      margin-left: 5vw;

      .infoheader {
        margin-top: 20px;
      }
    }
  }
}

.content {
  width: 50%;
  display: flex;
  flex-direction: column;
  @include mobile {
    //width: 45%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  text-align: left;

  .textfeld {
    width: 70%;
    text-align: left;
    margin: 10px 0;
    @include mobile {
      width: 90%;

      padding-right: 10px;
      font-size: 12px;
      line-height: 1.3em;
    }
  }
}

.infoheader {
  margin-top: 10vh;
  position: relative;
  @include mobile {
    margin-top: 20px;
  }

  h2 {
    width: 100%;
    @include mobile {
      font-size: 28px;
    }
  }
}

.nahrwerte {

  @include mobile {
    background-color: rgba(#fff, 0.7);
    padding: 0;
    font-size: 12px;

  }

  table {
    margin-top: 10px;
  }

  td {
    padding: 0 20px 0 0;

    &.desc {
      color: $gold;
    }
  }

  button {
    background-color: #fff;
    text-decoration: none;
    margin: 10px 0 0;
    font-weight: 700;

    a {
      padding: 10px;
      display: block;
      color: $gold;
      font-size: 22px;
      @include mobile {
        font-size: 16px;
        padding: 5px;
      }
    }

    border: 1px $gold solid;
  }
}

.products {
  flex-wrap: wrap;
  //margin-top: 100px;

  @include mobile {
    background-size: 150%;
    background-position: center 100px;
    padding-top: 100px;
    background-repeat: no-repeat;
  }

  @include desktop {
    background: #fff !important;
  }

  .arrow {
    position: absolute;
    top: 10vh;
    @include mobile {
      top: calc(10vh + 90px);

    }

    &.left {
      left: 5vw;
      @include mobile {
        left: 0;
      }
    }

    &.right {
      right: 5vw;
      @include mobile {
        right: 0;
      }
      z-index: 150;

    }
  }
}
</style>