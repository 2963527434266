import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vue2TouchEvents from 'vue2-touch-events'
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);
Vue.use(Vue2TouchEvents)
Vue.config.productionTip = false
import {scrollHandler, offset, resizeHandler,connectionCheck} from '@/mixins/index.js'
Vue.mixin(scrollHandler);
Vue.mixin(offset);
Vue.mixin(resizeHandler);
Vue.mixin(connectionCheck);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
