<template>
  <div class="bottle" :class="{overlap: scrollPosition<150}">
    <!--  <div class="bottle" :style="{transform: 'rotate('+scrollPosition+'deg)'}">-->

    <img src="@/assets/img/Meissener_Apfelbrand_Goldparmaene_350ml_web.png" alt=""
         :style="{transform: 'rotate('+getRotation(180)+'deg)', top: scrollPosition /3 + 'px'}" class="nomobile">
    <img src="@/assets/img/Meissener_Apfelbrand_Goldparmaene_350ml_web.png" alt=""
         :style="{transform: 'rotate('+getRotation(360)+'deg)', top: scrollPosition /3 + 'px'}" class="onlymobile">
  </div>
</template>

<script>
export default {
  name: "Bottle",
  data: function () {
    return {
    }
  },
  methods: {
    getRotation(deg) {
      let rotation = this.scrollPosition / window.innerHeight * deg
      return rotation > deg/2 ? deg/2 : rotation
    }
  },
}
</script>

<style scoped lang="scss">
.bottle {
  position: absolute;
  width: 100%;
  pointer-events: none;

  &.overlap {

    z-index: 1500;
    @include mobile {
      z-index: 999;
    }
  }

  height: 120vh;
  @include laptop {
    height: 100vh;
  }
  //background-color: yellow;
  img {
    height: 100%;
    transform-origin: center 45%;
    position: relative;
    @include mobile {
      //transform: rotate(0deg) !important;
      //margin-left: 30%;
      height: 100vh;
      top: 70px !important;
      left: 5px;
      transform-origin: center 35%;
    }
  }
}
</style>