<template>
  <div>
    <div v-for="k in uppercats" class="cats">
      <div class="uppercat">
          <img src="https://spezbrenn.zzagentur.de/dateien/2021/07/Submenu_Destillate.jpg" alt="" v-if="k.id===2">
          <img src="https://spezbrenn.zzagentur.de/dateien/2021/07/Submenu_SchokoladigesFruchtiges.jpg" alt="" v-if="k.id===5">

        <div class="catname">
          {{ k.name }}

        </div>
      </div>
      <div class="subcat" v-if="filterSubcats(k.id)">

        <div v-for="s in filterSubcats(k.id)" :key="s.name" v-if="findFirstProduct(s.name)">
          <router-link :to="shortLink(findFirstProduct(s.name).link)" class="catname">
            <!--{{findFirstProduct(s.name)}}-->

            {{ s.name }}
            <!--            {{ s.parent }}-->

          </router-link>

        </div>
      </div>
    </div>
    <!--    {{ uppercats }}-->
    <!--    {{ subcats }}-->
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "Productmenu",
  computed: {
    uppercats() {

      return store.state.uppercats
    },
    subcats: () => store.state.subcats

  },
  methods: {
    filterSubcats(parent) {
      return store.state.subcats.filter(s => s.parent === parent)
    },
    findFirstProduct(cat) {

      let prod = this.products.find(p => p.cat[0] === cat)
      return prod ? prod : false

    }
  },
  props: ['products']
}
</script>

<style scoped lang="scss">
.menu {
  width: calc(50%);
  overflow: auto;
  background-color: $gold;
  //border-top: 2px solid #fff;
  position: absolute;
  &.flow {
    position: relative;
    top: 0;
    height: auto;
    width: 100%;
    border: none;
    border-left: 1px solid $gold;
    .cats {
      border: none;
      border-bottom: 5px solid $gold;
    }
  }
  top: 100px;
  z-index: 200000;
  right: 0;
  @include mobile {
    width: 100%;
    right: 0;
    top: 200px !important;
    &.flow {
      top: 0!important;
    }
  }

  .cats {
    display: flex;
    background-color: #fff;
    border: 1px solid $gold;
    border-bottom: 5px solid lighten($gold,10%);

    .catname {
      border-bottom: 1px solid $gold;
      font-family: $headfont;
      font-size: 24px;
      display: block;
      padding: 10px;
      color: $gold;
      @include mobile {
        font-size: 20px;
      }

    }

    a.catname {

      cursor: pointer;


      &:hover, &.router-link-active {
      background-color: lighten($gold, 45%);
      }
    }

    .uppercat {
      width: calc(50% - 2px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
        @include desktop {
          width: 60%;
        }
      }
      .catname {
        text-align: center;
        border: none;
        padding: 0;
        padding-bottom: 5px;

      }
    }

    .subcat {
      & > div:last-child {
        a {

          border: none;
        }
      }

      width: calc(50% - 2px);
      border-left: 1px solid $gold;

      a {

      }
    }
  }
}
</style>