let $ = require('jquery');

export let scrollHandler = {
    data: function () {
        return {
            scrollPosition: 0,
            docHeight: 0,
            vpHeight: window.innerHeight,
        }
    },
    components: {},
    computed: {

    },
    methods: {
        handleScroll: function () {
            // jQuery('.slidecontent').css('transform', this.slideroffset(-0.2, window.pageYOffset))
            window.requestAnimationFrame(function () {
                window.pageYOffset >= 0 ? this.scrollPosition = window.pageYOffset : 0;
                if (this.$refs.home) {

                    this.docHeight = this.$refs.home.clientHeight
                    this.vpHeight = window.innerHeight

                }
            }
                .bind(this))
        },
        scrollToTop() {
            $('html,body').animate({scrollTop: 0}, 500);
        },

    },

    destroyed() {
        window.removeEventListener('DOMContentLoaded', this.handleScroll);
    },
    mounted: function () {
        window.addEventListener('scroll', this.handleScroll);
    },
    watch: {}
};

export let resizeHandler = {
    data: function () {
        return {
            scrHeight: 0,
            scrWidth: 0
        }
    },
    methods: {
        getSize: function () {
            this.scrHeight = window.innerHeight;
            this.scrWidth = window.innerWidth
            // console.log(this.scrHeight)
        },
        shortLink: function (link) {
            return link?link.replace(/^.*\/\/[^\/]+/, ''):false;
        },

    },
    destroyed() {
        window.removeEventListener('resize', this.getSize);
    },
    created: function () {
        this.getSize();
        window.addEventListener('resize', this.getSize)
    },

};

export let offset = {
    data: function () {
        return {
            offsetclass: false,
        }
    },
    updated: function () {

    },
    mounted: function () {
        this.offsetclass = false;
        this.$nextTick(function () {
            setTimeout(function () {
                this.offsetclass = true
            }.bind(this), 500)
        })
    },
};

export let connectionCheck = {

    data() {
        return {
            offline: false
        }
    },
    created() {
        window.addEventListener('online', this.networkChange);
        window.addEventListener('offline', this.networkChange)
    },
    methods: {
        networkChange(e) {
            this.offline = e.type == "offline";
        }
    }
};