import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        pages: [],
        base: '/wp-json/wp/v2/',
        products: [],
        uppercats: [],
        subcats: [],
        mainmenu: [],
        footermenu: []

    },
    mutations: {
        savePages(state, data) {
            state.pages = data;
        },
        saveProducts(state, data) {
            console.log(data.length)
            state.products = data;
        },
        saveCats(state, data) {
            state.uppercats = []
            state.subcats = []

            data.forEach(function (d) {
                d.parent === 0 ? state.uppercats.push(d) : state.subcats.push(d)
            })

        },
        saveMainmenu(state,data) {
            state.mainmenu = data
        },
        saveFootermenu(state,data) {
            state.footermenu = data
        }

    },
    actions: {
        getPages(store) {
            Axios.get(store.state.base + 'pages?_embed&per_page=100').then(function (response) {
                // window.sessionStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('savePages', response.data);
            });
        },
        getProducts(store) {
            Axios.get(store.state.base + 'produkte?orderby=menu_order&order=asc&per_page=100').then(function (response) {
                // window.sessionStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('saveProducts', response.data);
            });
        },
        getCats(store) {
            Axios.get(store.state.base + 'produkt_cats').then(function (response) {
                // window.sessionStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('saveCats', response.data);
            });
        },
        getMainmenu: function (store) {
            Axios.get('/wp-json/navi/main').then(function (response) {
                // window.sessionStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('saveMainmenu', response.data);

            });

        },
        getFootermenu: function (store) {
            Axios.get('/wp-json/navi/footer').then(function (response) {
                // window.sessionStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('saveFootermenu', response.data);

            });

        },
    },
    modules: {}
})
