<template>

  <div class="page2" :style="{backgroundImage:`url(${getGrafik()})`}">
    <div class="drop">

      <img src="@/assets/img/Tropfen_50ProzentGold.svg" alt="">
    </div>
    <div class="zitat" v-html="page.acf.zitat">

    </div>
    <div class="unterzeile" v-html="page.acf.unterzeile">


    </div>

  </div>

</template>

<script>
export default {
  name: "Page2.vue",
  props: ['page'],
  methods: {
    getGrafik() {
      return this.page && this.page.acf.bggrafik ? this.page.acf.bggrafik.sizes.wide : false
    },
  }
}
</script>

<style scoped lang="scss">
.page2 {
  padding-top: 100px;
  height: calc(100vh - 100px);
  background-color: $gold;
  color: #fff;
  overflow: hidden;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 50%;
  display: flex;
  flex-direction: column;





  @include mobile {
    background-size: contain;
  }
}
</style>