<template>
  <div class="page " :class="{ scrolled:scrollTimeout}">
    <div class="goldenheader mobile">

      <div class="head ">

        <h1>Eine köstliche <br> Visitenkarte Sachsens</h1>

      </div>
    </div>
    <div class="start ">

      <div class="goldenheader">
        <div class="head nomobile">

          <h1>Eine köstliche <br> Visitenkarte Sachsens </h1>
        </div>
        <div>

          <div class="drop ">
            <img src="@/assets/img/Icon_Tropfen_weiss.svg" alt="" :style="{'opacity': getOpacity(150)}"
                 class="nomobile">
            <img src="@/assets/img/Icon_Tropfen_weiss.svg" alt="" :style="{'opacity': getOpacity(250)}"
                 class="onlymobile">
            <div style="color: #fff;">
              <p>

                Sortenreine Destillate aus <br>
                einheimischem Obst sind unsere Passion. <br>
                Schonende Vergärung, sorgfältiges Brennen
                in kleinen kupfernen Brennblasen und viel Zeit
                für die Lagerung sind Grundlage für feine Spirituosen
                in unseren handgesiegelten und -etikettierten
                Flaschenunikaten. <br> Jedes Produkt eine
                köstliche Visitenkarte Sachsens.
              </p>
            </div>
          </div>
          <img src="@/assets/img/Grafik_Gold_Apfel.png" alt="" style="width: 100%;">

        </div>
      </div>
      <div class="pic">
        <!--      <img src="@/assets/img/Brennerei_Hauptmotiv_01_Streuobstwiese_Baum.jpg" alt="">-->
      </div>
      <Bottle></Bottle>

    </div>
  </div>

</template>

<script>

import Bottle from '@/components/parts/Bottle.vue'

export default {
  name: "Start",
  data: function () {
    return {}
  },
  components: {Bottle},
  methods: {
    getOpacity(offset) {
      return (this.scrollPosition - offset) / this.scrHeight;
    }
  },
  props: ['scrollTimeout']
}
</script>

<style scoped lang="scss">
.page {

}

h1 {
  line-height: 1.2em;
  font-size: 80px;
  height: 80vh;
  padding-top: 15vh;
  @include mobile {
    font-size: 40px;
    height: auto;
    padding-top: 0;
  }
}

.nomobile {
  @include mobile {
    display: none;
  }
}

.mobile {
  display: none;
  @include mobile {
    display: block;
  }
}

.drop {
  img {
    width: 200px;
  }

  margin-left: 20%;
  width: 60%;
  font-size: 20px;
  line-height: 1.4em;
  @include mobile {
    width: 90%;
    margin-left: 5%;
  }
}

.goldenheader {
  background-color: $gold;
  //min-height: 100vh;
  &.mobile {
    padding-top: 100px;
    padding-bottom: 10px;

  }
}

.start {
  background-color: #bbb;
  //max-height: 200vh;
  flex-direction: row;
  display: flex;
  align-items: stretch;
  padding-top: 90px;

  @include mobile {
    flex-direction: column;
    padding-top: 0;
  }

  .goldenheader {
    .head {
      height: 80vh;
      padding: 0 15%;
      @include mobile {
        height: auto;
        padding: 0;
      }
    }
    order: 2;
    width: 50%;
    @include mobile {
      width: 100%;
      //height: 40vh;
      order: 2;
    }
    //height: 100%;
  }
  .pic {
    background-position: -2% 100px;
    background-image: url('../assets/img/Brennerei_Hauptmotiv_01_Streuobstwiese_Baum.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    order: 1;
    background-color: $gold;
    background-size: 55% auto;
    @include mobile {
      background-size: cover;
      background-attachment: unset;
      background-position: 0 0;
      width: 100%;
      height: 80vh;
    }
    width: 50%;


  }
}
</style>