<template>
  <div class="header" :style="{height: getHeaderHeight()}" :class="{home:type==='home',sub:submenu}">
    <div class="left" style="max-height: 100%;height: 100%;display: flex;">

      <router-link to="/" class="block start " style="max-height: 100%;height: 100%;display: flex;align-items: center;">
        <img src="@/assets/img/Logo_Meissener_Spezialitaeten_Brennerei_Gold.svg" alt="">
      </router-link>
      <div class="menubutton onlymobile" @click="submenu=!submenu">
        <img src="@/assets/img/menu.svg" alt="">

      </div>
    </div>
    <div style=" " class="right">
      <div :class="{golden:page, nosubpath: !subpath }" class="pagelink ">
        <router-link tag="div" :to="'/'+page.slug" v-if="page" v-html="page.title.rendered">{{ }}</router-link>
        <div v-if="product" class="cat">
          <div>

            {{ product.cat[0] }}
          </div>
          <div class="productmenutrigger" @click="productmenu=!productmenu" :class="{active:productmenu}">

            <img src="@/assets/img/Icon_Tropfen_weiss.svg" alt="">
          </div>
        </div>

      </div>
      <div class="menubutton nomobile" style="cursor: pointer" @click="submenu=!submenu" :class="{active:submenu}">
        <img src="@/assets/img/menu.svg" alt="">
      </div>
    </div>
    <Submenu class="menu" :products="products" v-if="submenu" :style="{top: getHeaderHeight()}"></Submenu>
    <Productmenu class="menu" v-if="productmenu" :products="products"></Productmenu>

  </div>
</template>

<script>
import store from '@/store'
import Submenu from '@/components/parts/Submenu.vue'
import Productmenu from '@/components/parts/Productmenu.vue'

export default {
  name: "Header",
  data() {
    return {
      submenu: false,
      productmenu: false
    }
  },
  methods: {
    getHeaderHeight() {
      let height = this.scrollPosition > 200 || this.$route.meta.type !== 'home' ? 100 : 180 - this.scrollPosition / 2.2
      return height + 'px';
    },
    shortLink(url) {

      return url ? url.replace(/^.*\/\/[^\/]+/, '') : ''
    }
  },
  watch: {
    $route() {
      this.submenu = false
      this.productmenu = false
    },
    submenu() {
      if (this.submenu) this.productmenu = false
    },
    productmenu() {
      if (this.productmenu) this.submenu = false
    }
  },
  computed: {
    type() {
      return this.$route.meta.type;
    },
    subpath() {
      return this.$route.params.subslug || this.$route.params.product || false
    },
    product() {
      return this.products ? this.products.filter(p => p.slug === this.$route.params.product)[0] : false
    },
    mainmenu: () => store.state.mainmenu,
    footermenu: () => store.state.footermenu
  },
  props: ['page', 'products'],
  components: {Submenu, Productmenu}
}
</script>

<style scoped lang="scss">


.header {
  height: 100px;
  width: 100%;
  left: 0;
  text-align: left;
  top: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dotted $gold;
  position: fixed;
  display: flex;
  background-color: #fff;
  z-index: 1000;

  &.sub {
    z-index: 2000;
  }

  @include mobile {
    height: 200px !important;
    flex-direction: column;
    max-width: 100%;
    justify-content: center;
    &.home {
      height: 100px !important;

      .right {
        display: none;
      }
    }
  }

  .left {
    .start {
      @include mobile {

        width: 75%;
      }
    }
  }

  .right {
    display: flex;
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  .pagelink {

    div {
      width: 100%;
      display: flex;
      align-items: center;

      justify-content: center;
      height: 100%;
    }

    font-weight: 300;
    display: flex;
    color: #fff;
    font-size: 40px;
    font-family: 'Benchnine', Avenir, Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    height: 100px;
    width: 80%;
    @include mobile {
      width: 100%;
    }

    .cat {
      text-transform: capitalize;
      display: flex;
      font-size: 24px;

      .productmenutrigger {
        padding: 20px 0;
        height: 60px;
        border-left: 1px dotted white;

        &.active {
          background-color: lighten($gold, 10%);
        }

        img {
          height: 100%;
        }
      }
    }
  }

  .golden {
    background-color: $gold;
    cursor: pointer;
  }

  .nosubpath {

    opacity: 0;
    pointer-events: none;
    @include mobile {
      opacity: 1;
      pointer-events: all;
    }
    @include mobile {
      display: inline-flex;
    }
  }

  .menubutton {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
transition: background-color 0.2s ease;
    &:hover {
      background-color: rgba($gold, 10%);
    }  &.active {
      background-color: rgba($gold, 20%);
    }

    img {
      width: 50px;
    }
  }
}
</style>