<template>
  <footer>
    <img src="@/assets/img/Logo_Meissener_Spezialitaeten_Brennerei_Gold_zentriert.svg" alt="">
    <br>
    <div class="address">

    Meissener Spezialitätenbrennerei <br> Prinz zur Lippe GmbH & Co. KG <br>
    Siegbert Hennig, Geschäftsführender Gesellschafter <br>
    Dipl. Ing (FH), Destillateurmeister <br>
    OT Reichenbach Nr. 5, DE 01665 Klipphausen
    </div>
  </footer>
</template>

<script>
export default {
  name: "PageFooter"
}
</script>

<style scoped lang="scss">
footer {
  background-color: #fff;
  max-width: 100%;
  padding-top: 20px;
  width: 100%;
  .address {
    //width: 100%;
  padding: 20px 10px 50px;
  font-size: 14px;
    line-height: 1.3em;
    @include mobile {
      font-size: 13px;
    }
  }

  img {
    @include mobile {

    max-width: 100%;
    width: 100%;
    }
max-width: 40%;
  }
}
</style>