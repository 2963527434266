<template>
  <div class="page" v-if="page" :class="{hidden:isProduct||subpath, scrolled:scrollTimeout}">
    <div class="page1">

      <div class="bg" :style="{backgroundImage:`url(${getBackground()})`}">

      </div>
      <div class="content" :style="{backgroundImage:`url(${getBackground()})`}">
        <div style="" class="vertical">

          <h1 class="light" v-html="page.title.rendered"></h1>
          <div class="box">
            <router-link class="inner block" :to="buildLink()">
              <div class="text" v-html="page.acf.erklartext" style="text-align: left;">
              </div>
              <h2 style="text-align: right;" class="arrowheader">
                <span v-html="page.acf.linktext"></span><img src="@/assets/img/chevron-left-gold.svg" alt="">

              </h2>
            </router-link>
          </div>

        </div>

        <div class="page3 nomobile" :style="{backgroundImage:`url(${getGrafik()})`}">
          <div style="margin-bottom:50vh;" >
            <div class="drop nomobile">

              <img src="@/assets/img/Tropfen_50ProzentGold.svg" alt="">
            </div>
            <div class="zitat" v-html="page.acf.zitat">

            </div>
            <div class="unterzeile" v-html="page.acf.unterzeile">
            </div>
          </div>


      </div>
    </div>
  </div>
  <Page2 :page="page" class="onlymobile">

  </Page2>


  <Subpage v-if="subpath" :subpages="subpages" :subpage="getSubPage(this.$route.params.subslug)"></Subpage>
  <Products :product="product" :products="products" v-if="type==='produkte'&&isProduct">

  </Products>
  </div>
</template>

<script>
import Subpage from '@/components/Subpage.vue'
import Products from '@/components/Products.vue'
import Page2 from '@/components/parts/Page2.vue'
import store from "@/store";

export default {
  name: "Page",
  components: {Subpage, Products, Page2},
  created() {
  },

  methods: {
    getGrafik() {
      return this.page && this.page.acf.bggrafik ? this.page.acf.bggrafik.sizes.wide : false
    },
    getBackground() {
      return this.page && this.page.acf.hintergrund ? this.page.acf.hintergrund.sizes.wide : false
    },

    getSubPage(slug) {
      return this.subpages.filter(p => p.link === slug)[0]
    },
    getProduct(slug) {
      return this.products.filter(p => p.slug === slug)[0]
    },
    buildLink() {
      if (this.type === 'produkte' && this.products) {

        return this.products[0] ? '/produkte/' + this.products[0].slug : ''
      } else return this.subpages ? '/' + this.page.slug + '/' + this.subpages[0].link : ''
    }
  },
  computed: {
    product() {
      return this.products.filter(p => p.slug === this.$route.params.product)[0]
    },
    type() {
      return this.$route.meta.type;
    },
    subpages() {
      return this.page ? this.page.acf.unterseite : [];
    },
    subpath() {
      return this.$route.params.subslug || false
    },
    cats() {
      return store.state.cats
    },
    subcats() {
      return store.state.subcats
    },
    isProduct() {
      return this.$route.params.product || false
    }

  },
  props: ['page', 'products', 'scrollTimeout']
}
</script>

<style lang="scss">

.page {
  //max-height: 100vh;
  //position: absolute;

  transition: opacity 0.5s ease;
max-width: 100%;
  &.scrolled {
    opacity: 0;
    transition: opacity 0s ease;
  }

  .bottle {
    transition: transform 0.5s ease 0.5s;
    transform: translateY(0px);
  }

  &.scrolled {
    .bottle {
      transform: translateY(100px);
    }
  }

  &.hidden {
  }

  .page2 {
    //display: none;
  }

  &.hidden {
    max-height: calc(100vh - 100px);
    overflow: hidden;

    .page1 {
      display: none;
    }

    .page2 {
      display: none;
    }
  }
}

.page1 {
  padding-top: 100px;
  min-height: calc(100vh - 100px);
  height: auto;
  display: flex;

  align-items: stretch;

  .bg {
    width: 50%;
    position: fixed;
    left: 0;
    height: calc(100vh - 100px);
    //background-attachment: fixed;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    @include mobile {
      width: 100%;
      position: relative;

    }
  }

  .content {
    margin-left: 50%;
    height: auto;
    width: 50%;
    background-size: cover;
    background-position: bottom center;
    @include mobile {
      width: 100%;
      margin-left: 0;
    }
    @include desktop {
      background: $gold !important;
    }

    .vertical {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //height: 100%;
min-height: calc(100vh - 100px);
      .text {
        @include mobile {
          font-size: 14px;
        }

      }

      .arrowheader {

        img {
          height: 40px;
          @include mobile {
            height: 22px;
          }
        }
      }

      @include mobile {
        height: calc(100vh - 100px);
        //justify-content: flex-end;
      }

      h1 {
        margin-top: 100px;
        @include mobile {
          margin: 0;
          visibility: hidden;
        }
      }

      .box {
        margin: 50px;
        background-color: beige;
        padding: 10px;
        cursor: pointer;

        @include mobile {
          border-right: none;
          margin-right: 0;
          padding-right: 0;
        }

        .inner {
          border: 1px solid black;
          padding: 10px;
          @include mobile {
            border-right: none;

          }

          h2 {
            color: $gold;

          }
        }
      }
    }
  }

  .bg {
    @include mobile {
      display: none;
    }
  }
}

.simplecontent {
  height: auto;
  font-size: 14px;
  line-height: 1.4em;

  h1, h2, h3, h4 {
    color: #fff;
  }

  h4 {
    font-family: Georgia, Avenir, Helvetica, Arial, sans-serif;
    font-size: 24px;
    line-height: 1.4em;
    font-weight: 400;
    @include mobile {
      font-size: 18px;
    }
  }

  h2 {
    font-weight: 300;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.zitat {
  font-size: 60px;
  @include mobile {
    font-size: 40px;
  }
  line-height: 1.2em;
  color: #fff;
  padding: 0 10px;
  margin: 20px 0;
  font-family: 'Benchnine', Avenir, Helvetica, Arial, sans-serif;
}

.drop {
  img {
    max-height: 100%;
    height: 150px;
  }
}

.page3 {
  min-height:90vh;
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
}

</style>