<template>
  <div>
    <div v-for="m in mainmenu" :key="m.title">

      <router-link :to="shortLink(m.url)" class="block" v-html="m.title" v-if="shortLink(m.url)!=='/produkte/'">
      </router-link>
      <div v-else style="position: relative;">

        <router-link :to="shortLink(m.url)" class="block" v-html="m.title">
        </router-link>
        <div class="switch" @click="flowproductmenu=!flowproductmenu" :class="{active:flowproductmenu}">
          <img src="@/assets/img/Icon_Tropfen_weiss.svg" alt="">
        </div>
      <Productmenu class="menu flow" v-if="flowproductmenu"  :products="products"></Productmenu>
      </div>
<!--      <Productmenu class="menu flow"  v-if="flowproductmenu" :products="products"></Productmenu>-->
    </div>

    <div style="display: flex;" class="subsub">

      <router-link :to="shortLink(m.url)" v-for="m in footermenu" :key="m.title" v-html="m.title"></router-link>

    </div>
  </div>
</template>

<script>
import store from '@/store'
import Productmenu from '@/components/parts/Productmenu.vue'

export default {
  name: "Submenu",
  data() {
    return {
      submenu: false,
      flowproductmenu: false
    }
  },
  components: {Productmenu},
  computed: {

    mainmenu: () => store.state.mainmenu,
    footermenu: () => store.state.footermenu
  },
  props: ['products'],
}
</script>

<style scoped lang="scss">

.menu {
  max-height: calc(100vh - 100px);
  overflow: auto;
  width: calc(50%);
  background-color: #fff;
  border-top: 2px solid #fff;
  position: fixed;
  top: 100px;
  z-index: 100000;
  right: 0;
  @include mobile {
    width: 100%;
    right: 0;
    top: 100px !important;
  }

  a {
    background-color: $gold;
    transition: background-color 0.2s ease;
    color: #fff;
    text-align: center;
    position: relative;
    text-transform: uppercase;
    font-family: 'Benchnine', Avenir, Helvetica, Arial, sans-serif;
    font-size: 30px;
    padding: 15px 5px;
    border-bottom: 1px solid #fff;
    @include mobile {
      padding: 18px 5px;
      font-size: 30px;
    }

    &.router-link-exact-active {
      //background-color: #fff;
      background-color: lighten($gold, 10%);
      color: #fff;
    }

    &:hover {
      //background-color: #fff;
      background-color: lighten($gold, 15%);
      color: #fff;
    }

  }
.switch {
  position: absolute;
  height: 58px;
  right: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #fff;
  width: 100px;
  cursor: pointer;
  &.active {
    background-color: lighten($gold, 15%);
  }
  img {
    //height: 60%;
    height: 60%;
  }
}
  .subsub {
    display: flex;
    background-color: $gold;
    justify-content: space-around;
    border-bottom: 1px solid #fff;
    //flex-wrap: wrap;
    a {
      text-transform: capitalize;
      border-right: 1px solid #fff;
      border-bottom: none;
      //margin: 10px;
      padding: 15px 10px;
      font-size: 20px;
      width: 100%;

      &:last-child {
        border: none;
      }
    }

  }
}
</style>