<template>
  <div class="home" ref="home" id="home" @wheel="wheelHandler" v-touch:swipe.down="down" v-touch:swipe.up="up"
       :class="{overlay:overlay}">
    <Overlay v-if="overlay" @closeOverlay="closeOverlay"></Overlay>
    <Header :page="getPage('produkte')" :products="products" v-if="type==='produkte'"></Header>
    <Header :page="getPage(this.$route.params.slug)" :products="products" v-else></Header>
    <Start v-if="type==='home'" :scrollTimeout="scrollTimeout"></Start>
    <!--<Page  :page="getPage('passion')" ></Page>-->
    <SimplePage :page="thispage" v-if="type==='page' && thispage && thispage.template==='simple.php'"
                :scrollTimeout="scrollTimeout"></SimplePage>
    <Page :page="thispage" v-else-if="type==='page' && getPage(this.$route.params.slug) "
          :scrollTimeout="scrollTimeout" :class="{overlay:overlay}"></Page>
    <Page :page="productpage" v-if="type==='produkte'" :products="products" :scrollTimeout="scrollTimeout"
          :class="{overlay:overlay}"></Page>
    <div class="down">

      <a :href="shortLink(nextPage('next').url)" v-if="nextPage('next')" :class="{'invisible': scrollTimeout}"
         @click.prevent="goNext">
        <!--      {{scrollPosition + vpHeight}} {{docHeight}}-->
        <!--       {{vpHeight}}-->
        <img src="@/assets/img/chevron-left-white.svg" alt="">
      </a>
    </div>
    <PageFooter v-if="!nextPage('next')">

    </PageFooter>
  </div>
</template>

<script>
// @ is an alias to /src
import store from "@/store";
import Header from '@/components/Header.vue'
import Start from '@/components/Start.vue'
import Page from '@/components/Page.vue'
import SimplePage from '@/components/SimplePage.vue'
import PageFooter from '@/components/parts/PageFooter.vue'
import Overlay from '@/components/Overlay.vue'

export default {
  name: 'Home',
  data() {
    return {
      menu: [
        {
          'slug': 'passion'
        }
      ],
      scrollTimeout: false,
      overlay: false
    }
  },

  created() {
    store.dispatch('getPages')
    store.dispatch('getProducts')
    store.dispatch('getMainmenu')
    store.dispatch('getFootermenu')
    store.dispatch('getCats')
    let bot = /bot|googlebot|crawler|spider|robot|crawling|Lighthouse|Chrome-Lighthouse/i.test(navigator.userAgent)
    console.log(bot)
     this.overlay = !bot? !this.$cookie.get('is18', true) : false;
    this.overlay ? document.body.classList.add('overlay') : document.body.classList.remove('overlay')
  },
  components: {
    Header, Start, Page, SimplePage, PageFooter, Overlay
  },
  watch: {
    scrollTimeout() {
      document.getElementById('app').scrollIntoView();
    },
    $route() {
    },
    overlay() {
      this.overlay ? document.body.classList.add('overlay') : document.body.classList.remove('overlay')
    }
  },
  computed: {
    bottom() {
      return this.scrollPosition > 0 ? Math.round(this.docHeight) <= Math.round(this.vpHeight + this.scrollPosition) + 30 : false
    },
    type() {
      return this.$route.meta.type;
    },
    mainmenu: () => store.state.mainmenu,
    products() {
      let prods = [];
      store.state.products.map(function (p) {
        p.cat = []
        p.uppercat = []
      })
      //nach Subkategorien sortierte Produkte
      for (const uc of store.state.uppercats) {
        for (const c of store.state.subcats) {
          for (const p of store.state.products) {
            if (p.produkt_cats.indexOf(c.id) !== -1 && c.parent === uc.id) {
              p.cat.push(c.name);
              if (p.produkt_cats.indexOf(uc.id) !== -1) {
                p.uppercat.push(uc.slug);
              }
              prods.push(p)
            }
          }

        }
      }
      return prods;
    },
    thispage() {
      return this.$route.params.slug ? store.state.pages.filter(p => p.slug === this.$route.params.slug)[0] : false
    },
    productpage() {
      return store.state.pages.filter(p => p.slug === 'produkte')[0]
    },
    subpath() {
      return this.$route.params.subslug || this.$route.params.product || false
    },
  },
  methods: {
    closeOverlay() {
      this.overlay =false
      this.$cookie.set('is18', true, 1);
    },
    nextPage(dir) {
      let index = this.type === 'home' ? -1 : false;
      if (this.thispage || this.productpage) {
        store.state.mainmenu.forEach(function (m, i) {
          // console.log(m.url)
          if (m.url === this.thispage.link) index = i
          if (this.type === 'produkte') {
            if (m.url === this.productpage.link) index = i
          }

        }.bind(this))
      }
      if (dir === 'prev') {
        if (index === 0) return {url: '/'}
        else {
          return store.state.mainmenu[index - 1] && index > -1 ? store.state.mainmenu[index - 1] : false
        }
      } else {
        return index !== false ? store.state.mainmenu[index + 1] : false;
      }
    },
    wheelHandler(e) {
      if (this.scrollTimeout) {
        e.preventDefault()
      }
      if (e.deltaY > 0) {
        this.down(e)
      }
      if (e.deltaY < 0) {
        this.up(e)
      }
    },
    down(e) {
      if (this.bottom && !this.scrollTimeout && !this.subpath) {
        // if (e) e.preventDefault
        if (this.nextPage('next')) this.goNext()
      }
    },
    up() {
      if (this.scrollPosition === 0 && !this.scrollTimeout && !this.subpath) {
        this.goPrev()
      }
    },
    goPrev() {
      if (this.nextPage('prev')) {

        this.$router.push(this.shortLink(this.nextPage('prev').url))
        this.setScrollTimeout(500)
      }
    },
    goNext() {
      this.$router.push(this.shortLink(this.nextPage('next').url))
      this.setScrollTimeout(600)
    },
    setScrollTimeout(time) {
      this.scrollTimeout = true
      setTimeout(function () {
        this.scrollTimeout = false
      }.bind(this), time || 1000)
    },
    getPage(slug) {
      return slug ? store.state.pages.filter(p => p.slug === slug)[0] : false
    }
  }

}
</script>
<style lang="scss">
.home {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: $gold;
  //max-height: 100vh;
  //height: 100vh;

}

.overlay {
  .start {

    overflow: hidden;
  }
}

.down {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: transparent;
  //position: absolute;
  a {
    &.invisible {
      opacity: 0.3;
    }

    display: block;
    width: 100%;
  }

  img {
    transform: rotate(90deg);
    width: 50px;
  }
}
</style>
