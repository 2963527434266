<template>
  <div class="page" :class="{ scrolled:scrollTimeout}">
    <div class="page1">
      <div class="bg">

      </div>
      <div class="simplecontent content">
        <div style="" class="vertical">

          <h1 class="light" v-html="page.title.rendered"></h1>
          <simplecontent class="inhalt" :content="page.content.rendered"></simplecontent>
          <div class="block" v-for="b in page.acf.block" :key="b.header">
            <h2 v-html="b.header"></h2>
            <simplecontent class="inhalt" :content="b.inhalt"></simplecontent>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import simplecontent from '@/components/parts/simplecontent.vue'

export default {
  name: "SimplePage",
  components: {simplecontent},
  props: ['page', 'scrollTimeout']
}
</script>

<style scoped lang="scss">
.simplecontent {
  background-color: $gold;
  color: #fff;
  max-width: 100%;
  @include mobile {
    padding-top: 150px;
  }
}

.vertical {
  height: auto;
  max-height: unset!important;
  @include mobile {
    height: auto!important;
    //justify-content: flex-end;
  }
  h1 {
    @include mobile {
     display: none;
    }
  }
}

.page1 {
  height: auto;

  .simplecontent {
    height: auto;

    .inhalt {

      padding: 0 50px;
      @include mobile {
        padding: 0 10px;
      }
    }


    h2 {
      width: 70%;
      margin-left: 15%;
      color: #fff;
      border-top: 2px solid #fff;
      border-bottom: 2px solid #fff;
      padding: 10px 0;
    }
  }


  .bg {
    background-attachment: fixed;
    //background-color: $gold;
    height: auto;
    width: 25%;
  }
  .content {
    @include mobile {
      margin-left: 0;
    }
    margin-left: 25%;
  }
}


</style>