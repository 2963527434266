<template>
<div >
  <div v-html="content"></div>

</div>
</template>

<script>
export default {
  name: "simplecontent",
  props: ['content']
}
</script>

<style scoped lang="scss">

</style>