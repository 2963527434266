<template>
  <div class="subpage" v-touch:swipe.right="goLeft" v-touch:swipe.left="goRight">
    <div class="bg" :style="{backgroundImage:`url(${getBackground()})`}"></div>
    <div class="content">
      <div class="info">
        <div class="infoheader">
          <div class="arrow">

            <router-link v-if="position>0" :to="'/'+page+'/'+prevLink()"><img class="chevron left"
                                                                              src="@/assets/img/chevron-left.svg"
                                                                              alt=""></router-link>
          </div>
          <h2 v-html="subpage.uberschrift"></h2>
          <div class="arrow">

            <router-link v-if="subpages[position+1]" :to="'/'+page+'/'+nextLink()"><img class="chevron right"
                                                                                        src="@/assets/img/chevron-left.svg"
                                                                                        alt=""></router-link>
          </div>
        </div>
        <div class="textfeld" v-html="subpage.textfeld"></div>

        <div class="button" v-if="subpage.button && subpage.button.buttonlink">
          <div class="internal" v-if="subpage.button.linktype">
            <router-link :to="shortLink(subpage.button.interner_link)"><span>

              {{ subpage.button.buttontext }}
            </span>
              <img src="@/assets/img/chevron-left-gold.svg" alt=""></router-link>

          </div>
          <div class="external" v-else>
            <a :href="subpage.button.externer_link" target="_blank">{{ subpage.button.buttontext }}</a>
          </div>

        </div>
      </div>
      <div class="bar">
        <router-link tag="div" v-for="s in subpages" :key="s.link" :to="'/'+page+'/'+s.link" class="red"
                     :style="{width: getSubWidth()}">

        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Subpage",
  props: ['subpages', 'subpage'],
  methods: {
    getSubWidth() {
      return 100 / this.subpages.length + '%'
    },
    nextLink() {
      return this.subpages[this.position + 1].link
    },
    prevLink() {
      return this.subpages[this.position - 1].link
    },
    getBackground() {
      return this.subpage.bild.sizes.wide
    },
    goLeft() {
      if (this.position > 0) this.$router.push('/' + this.page + '/' + this.prevLink())
    },
    goRight() {
      if (this.subpages[this.position + 1]) this.$router.push('/' + this.page + '/' + this.nextLink())
    }
  },
  computed: {
    page() {
      return this.$route.params.slug
    },
    position() {
      let arr = this.subpages.map(function (s) {
        return s.link
      })
      return arr.indexOf(this.subpage.link)
    }
  }
}
</script>

<style scoped lang="scss">
.subpage {
  overflow: hidden;
  @include mobile {

    flex-direction: column;
    .info {
      order: 2;
      padding-top: 20px;
    }
    .bar {
      order: 1;
      height: 10px !important;
    }
  }
}

.button {
  margin-left: 100px;
  width: 100%;
  text-align: left;
  font-size: 18px;
  line-height: 1.3em;
  margin-top: 30px;
  display: flex;
  @include mobile {
    margin-left: 0;
    padding: 0 30px 0 50px;
  }
  a {
  justify-content: space-between;
    padding: 15px 20px;
    color: $gold;
    border: 1px solid $gold;
    font-weight: bold;
    width: 100%;
    display: flex;
    &:hover {
      background-color: lighten($gold, 50%);
    }


  }
}

.textfeld {
  margin-left: 100px;
  width: 60%;
  text-align: left;
  font-size: 18px;
  line-height: 1.3em;
  @include mobile {
    margin-left: 0;
    width: 80%;
    font-size: 14px;
    padding: 0 30px 0 50px;
  }
}

.bg {
  width: 50%;
  background-size: cover;
  @include mobile {
    width: 100%;
    height: 40vh;
    padding-top: 100px;
    background-position: center 100px;
  }
}

.content {
  width: 50%;

  @include mobile {
    width: 100%;

  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .bar {
    height: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .red {
      transition: background-color 0.5s ease;

      background-color: #fff;
    }

    .router-link-active {
      background-color: $red;

    }
  }
}
</style>