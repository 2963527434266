<template>
  <div class="overlay" style="max-width: 100%;">
    <div class="box"
         style="">
      <div style="position: relative;background-color: transparent;display: flex;flex-direction: column;justify-content: space-around;align-items: center">

        <div style="display: flex;flex-direction:column; align-items: center;position:relative;z-index:10">
        <img src="@/assets/img/Logo_Meissener_Spezialitaeten_Brennerei_Gold_zentriert.svg" alt=""
             style="max-width: 80%;">

          <div class="text" style="max-width: 400px;">
            Verantwortungsvoller Umgang mit alkoholischen Getränken steht im Mittelpunkt unserer Philosophie. <br> Der
            Zugang zu
            weiteren Informationen der Meissener Spezialitätenbrennerei Prinz zur Lippe setzt voraus, dass Sie
            mindestens
            18
            Jahre alt sind.
          </div>
        </div>
        <div style="display: flex;justify-content: center;position: relative;background-color: transparent;z-index:10;">
          <div class="buttons" style="max-width: 400px;margin-bottom: 20px;">

            <a href="https://www.kika.de"> <img src="@/assets/img/chevron-left.svg" alt=""
                                                style="width: 10px;transform:rotate(180deg)"> &nbsp;Nein, ich bin
              noch<span
                  style="color:darkred"> keine 18 Jahre</span></a>
            <br>
            <a @click="closeOverlay()">Ja, ich bin mindestens 18 Jahre alt &nbsp; <img
                src="@/assets/img/chevron-left.svg"
                alt=""
                style="width: 10px;"></a>
          </div>
        </div>
        <div></div>
        <img src="@/assets/img/zeichnung_brennerei_obst.jpg" alt=""
             style="width: 70%;position: absolute;bottom: 0;right: 0;z-index: 0">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Overlay",
  methods: {
    closeOverlay() {
      this.$emit("closeOverlay")
    }
  }

}
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba($gold, 0.4);
  z-index: 10000000000;
  display: flex;
  justify-content: center;
  align-items: center;


  .box {
    width: 30%;
    overflow: hidden;
    position: relative;
    min-width: 500px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 70%;
    //background-color: #fff;
    background-color: #fff;
    padding: 20px;
    @include mobile {
      //width: 100%;
      min-width: 100%;
    }
  }

  .text {
    color: #888;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    padding: 10px 20px;
  }

  a, button {
    //display: block;
    font-size: 16px;
    padding: 10px;
    font-weight: 700;
    width: calc(100% - 40px);
    //height: 32px;
    background-color: #fff;
    border: 1px solid $gold;
    color: $gold;
    margin: 5px 10px;
    cursor: pointer;
    display: inline-block;
  }
}
</style>